.uploadBoxContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    margin: 10px auto;
    padding: 20px 20px;
}

.uploadBox {
    border: 2px dashed #ccc;
    border-radius: 10px;
    width: 100%;
    padding: 40px;
    text-align: center;
    background-color: #fff;
}

.uploadIcon {
    font-size: 40px;
    color: #666;
    margin-bottom: 20px;
}

.uploadTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.instructions {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
}

.browse {
    color: #ff6600;
    cursor: pointer;
    text-decoration: underline;
}

.uploadInput {
    display: none;
}

.button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.status {
    margin-top: 10px;
}

.progress {
    margin-top: 20px;
    width: 100%;
    height: 10px;
    background-color: #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
}

.progressBar {
    height: 100%;
    background-color: #007BFF;
    -webkit-transition: width 0.3s ease;
    -o-transition: width 0.3 ease;
    transition: width 0.3 ease;
}

.error {
    color: red;
    margin-top: 10px;
}

.file-content {
    margin-top: 20px;
    text-align: right;
}

.file-content table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
}

.file-content table,
.file-content th,
.file-content td {
    border: 1px solid #ddd;
}

.file-content th,
.file-content td {
    padding: 8px;
    text-align: left;
}

.file-content tr:nth-child(even) {
    background-color: #f2f2f2;
}

td {
    font-size: 14px;
}

tbody{
    overflow: scroll;
}
@media screen and (max-width: 576px) {
    td {
        font-size: 8px;
    }
}