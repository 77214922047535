.navbarLanding {
  width: 100%;
  height: 100px;
  position: fixed;
  z-index: 99;
  transition: 0.5s ease;
  background-color: #f8f9fe;
}

.navbarWrapper {
  height: 100%;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
}

.navbarLeft {
  display: flex;
  gap: 1.5em;
  align-items: center;
  justify-content: space-between;
}

.startBtn {
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  background-color: #4bb6df;
  color: #ffffff;
}

.startBtn:hover {
  background-color: #0d173b;
  color: #ffffff;
}

.logoImage {
  width: 250px;
  height: auto;
}

@media screen and (max-width: 576px) {
  .startBtn {
    display: none;
  }
  .logoImage {
    width: 150px;
  }
  .navbarWrapper {
    justify-content: left;
  }
  .navbarLanding {
    height: 60px;
  }
}