.bodySection {
  display: flex;
  flex-direction: column;
}

.classList {
  display: flex;
  justify-content: space-between;
}

.assistantStudentPayments {
  display: flex;
  gap: 40px;
  align-items: end;
  border: 1px solid #cad1d7;
  border-radius: 20px;
  padding: 20px;
  margin-right: 10px;
  justify-content: space-between;
}

.classPaymentOptions {
  max-height: 450px;
  overflow-y: auto;
}

.paymentOptions {
  display: grid;
  grid-column-gap: 10px;
}

.paymentBtn {
  display: flex;
  align-items: stretch;
}

.paidBtn,
.unpaidBtn {
  flex-grow: 1;
  height: 100%;
  color: white;
  border: 1px;
  width: 170px;
}

.paidBtn {
  background-color: green !important;
  color: white !important;
}

.unpaidBtn {
  background-color: red;
}

.fieldSetPayOptions {
  margin-bottom: 0px;
  text-align: center;
}

.studentPaymentImage {
  width: 150px;
  height: auto;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 20px;
}

.studentDetailsClassAssistant {
  text-align: center;
}

.radioOptions {
  display: flex;
  gap: 10px;
}

.classDetails {
  width: 180px;
}

.studentDetailsClassAssistant {
  width: 250px;
}

.studentSection {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.studentPaymentImage {
  /* margin-left: 25px; */
}

.payHistory {
  display: flex;
  gap: 10px;
}

.barcodeSearch {
  display: flex;
  gap: 20px;
}

.barcodeSearchInput {
  width: 200px !important;
}

.visiblePayOptions {
  position: relative;
  margin-right: 0 !important;
  cursor: pointer;
}

.editBtn {
  width: 170px;
  margin-top: 5px;
  height: 30px;
  padding: 5px;
}

.classDetails p {
  font-weight: 600;
}

.payEdtiBtnsSection {
  display: flex;
  justify-content: space-between;
}

.dateSelector {
  height: 47px;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  padding-top: 1rem;
}

@media screen and (max-width: 1024px) {
  .classList {
    flex-direction: column;
  }

  .studentSection {
    margin: 0 auto;
    justify-content: center;
  }
}