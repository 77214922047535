.heroContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  box-sizing: border-box; 
}

.heroSection {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 120px 40px 0px 40px;
  text-align: center;
  max-width: 1440px;
  width: 100%;
  box-sizing: border-box; 
}

.heroSectionTopTextWrapper {
  max-width: 640px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  text-align: left;
}

.heroSectionImageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.heroSectionImage {
  max-width: 700px;
  width: 100%;
  height: auto;
}

.heroDescription {
  font-size: 20px;
  font-weight: 400;
  color: #737373;
  margin: 0;
  padding-left: 20px;
}

h1 {
  font-size: 56px;
  line-height: 68px;
  font-weight: 800;
}

.getBtn {
  width: 200px;
  height: 50px;
  padding: 10px 20px;
  border-radius: 40px;
  border: none;
  background-color: #4bb6df;
  color: #ffffff;
  font-size: 16px;
  margin-top: 10px;
}

.getBtn:hover {
  background-color: #0d173b;
  color: #ffffff;
}

.aboutContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  box-sizing: border-box; 
}

.aboutUsSection {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 60px 40px 0px 40px;
  text-align: center;
  max-width: 1440px;
  width: 100%;
  box-sizing: border-box; 
}

.aboutBottom {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: top;
  margin-top: 20px;
}

.aboutLeft, .contactRight {
  max-width: 640px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  text-align: left;
}
.aboutImg {
  max-width: 550px;
  width: 100%;
  height: auto;
}

.contactContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  box-sizing: border-box; 
}

.contactUsSection {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 60px 40px 0px 40px;
  text-align: center;
  max-width: 1440px;
  width: 100%;
  box-sizing: border-box; 
}

.contactBottom {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: top;
  margin-top: 20px;
}

/* .contactLeft {
  max-width: 640px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  text-align: left;
} */
.contactImg {
  max-width: 550px;
  width: 100%;
  height: auto;
}

.email, .phoneNumber, .location {
  display: flex;
  align-items: center;
  gap: 10px;
}

.emailDetails {
  text-align: left;
}

.iconClass {
  width: 60px;
}

.iconSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footerSection {
  width: 100%;
  display: flex; 
  justify-content: center; 
  align-items: center; 
  box-sizing: border-box; 
}

.footerContent {
  display: flex;
  padding: 60px 40px 20px 40px;
  text-align: center;
  max-width: 1440px;
  width: 100%;
  box-sizing: border-box; 
}

.startPhnNumber {
  margin-bottom: 0px;
}

.contacttNumber {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
}

.iconSection a {
  font-size: 20px;
}


@media screen and (min-width: 1025px) {
  .heroContainer {
    height: calc(140vh);
  }
}

@media screen and (min-width: 1440px) {
  .heroContainer {
    height: calc(100vh);
  }
}

@media screen and (max-width: 1280px) {
  .heroSectionTopTextWrapper h1 {
    font-size: 42px;
    line-height: 1.5;
  }

  .heroDescription {
    font-size: 18px;
  }

  .heroSectionTopTextWrapper {
    gap: 2.4em;
    max-width: 550px;
  }

  .heroSectionImage {
    max-width: 500px;
  }
  
  .aboutBottom, .contactBottom {
    gap: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .heroSection {
    flex-direction: column;  
    align-items: center;
  }

  .heroSectionTopTextWrapper {
    text-align: center;
  }
  
  .heroDescription {
    list-style: none; 
    padding-left: 0px !important;
  }
  .aboutBottom {
    flex-direction: column;  
    align-items: center;
  }
  .aboutLeft {
    text-align: center;
    margin-bottom: 40px;
  }
  .features {
    list-style: none;
    padding-left: 0px !important;
    font-weight: bold;
  }
  .contactBottom {
    flex-direction: column;  
    align-items: center;
  }
  .contactRight {
    text-align: center;
    margin-top: 40px;
  }
  .iconSection {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 20px;
  }
  .aboutUsSection h1, .contactUsSection h1 {
    font-size: 48px;
}
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 576px) {
  .heroSectionTopTextWrapper h1 {
    font-size: 32px; 
  }
  
 .aboutUsSection h1, .contactUsSection h1 {
      font-size: 40px;
  }
  .giveUsCall {
    margin-bottom: 20px;
  }
}
