.teacherPaymentHeader {
    display: flex;
    justify-content: space-between;
}

.teacherPaymentBtn {
    display: flex;
    flex-direction: column;
    align-items: flex-end; 
    gap: 10px;
}

.exportPaymentBtn {
    padding: 0.3rem 0.6rem;
}

@media screen and (max-width: 576px) {
    .teacherPaymentHeader {
        flex-direction: column;
    }
    .teacherPaymentBtn {
        align-items: flex-start;
    }
}