* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }
  
  .profile-card {
    width: 300px;
    background: linear-gradient(to bottom, #0d173b 30%, #fff 0%);
    border-radius: 15px;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    padding: 20px 20px 10px 20px;
    position: relative;
    margin-right: 0 !important;
    margin-bottom: 10px;
    /* height: 310px; */
  }  
  
  .profile-card-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  
  .icon-back,
  .icon-menu {
    font-size: 20px;
    cursor: pointer;
  }
  
  .profile-img img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  h2 {
    font-size: 1.2em;
    margin-bottom: 0px;
  }
  
  p {
    color: #777;
  }
  
  .action-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
  
  .btn-message,
  .btn-subscribe {
    border: none;
    color: white;
    padding: 6px 20px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 0.9em;
    height: 2.2rem;
    width: 150px;
  }
  
  .stats {
    display: flex;
    justify-content: space-around;
  }
  
  .stat-item {
    text-align: center;
  }
  
  .stat-item span {
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .stat-item p {
    color: #777;
  }
  