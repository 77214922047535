.qRCard {
  background-color: #fae825;
  color: #000000;
  padding: 10px;
  width: 55mm;
  height: 87mm;
  border-radius: 0 !important;
}

.qRCardBack {
  background-color: #F8E413;
  color: #000000;
  width: 55mm;
  height: 87mm;
  border-radius: 0 !important;
  padding: 20px 0 0 0 !important;
}

.idCardHeader {
  background-color: white;
  color: #000000;
  padding: 0 0 10px 0;
  text-align: center;
}

.web {
  font-size: 6px;
  padding-top: 3px;
}

.title {
  font-size: 12px;
  padding-bottom: 0px;
  font-weight: bold;
  color: #000000;
  line-height: 1rem;
  margin: 0 auto;
}

.phoneNumber {
  font-size: 10px;
  line-height: 1rem;
}

.regNumber {
  font-size: 10px;
  line-height: 0.8rem;
  color: #000000;
  margin: 0 auto;
  padding-top: 10px;
}

p {
  margin-bottom: 0rem;
}

.emailId {
  text-align: center;
}

.instAddress {
  font-size: 8px;
  line-height: 0.8rem;
  color: #000000;
}

.qrBody {
  padding-top: 0;
  text-align: center;
}

.qrFooter {
  padding: 0 10px;
  text-align: center;
  border-top: none;
}

.barCodeId {
  width: 100%;
  color: black;
}

.instituteDetails {
  display: flex;
  justify-content: space-between;
  border: 1px solid #2F4FA2;
  width: 100%;
  padding: 3px;
}

.instLogo{
  width: 40px;
  height: 40px;
}
.stuImageId {
  padding: 2px;
  border-radius: 5px;
  background-color: #ffffff;
}

.code {
  padding: 3px;
  background-color: #ffffff;
}

.terms {
  color: #000000;
  font-size: 8px;
  line-height: 1.5;
  padding: 0 20px;
}

.termsTitle {
  font-size: 10px;
  color: #000000;
  font-weight: 500;
  margin-top: 10px;
}

.qrBackBody {
  padding: 0 0 10px 0;
  text-align: center;
}

.instDetails {
  background-color: #2F4FA2;
  padding: 10px;
}

.instDetails p{
  color: #ffffff;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 1;
}

.ardilla {
  font-size: 10px;
  color: #000000;
  margin-bottom: 0;
  margin-top: 5px;
}

.shape {
  position: relative;
  overflow: hidden;
}

.shape::after {
  border-radius: 100%;
  position: absolute;
  background: #2F4FA2;
  right: -200px;
  left: -200px;
  top: -200px;
  content: '';
  bottom: 0;
}

.instName {
  color: #ffffff;
  font-size: 8px;
  margin-top: 10px;
  line-height: 1;
}